<template>
  <el-form
    :model="form"
    :rules="rules"
    ref="blogForm"
    label-width="120px"
    label-position="top"
    class="company-ruleForm"
    status-icon
    v-loading="loading"
    :disabled="submitting"
  >
    <el-row :gutter="10">
      <el-col :xs="24" :sm="12" :md="8">
        <el-form-item label="标题" prop="title" class="base-form-item">
          <el-input
            v-model.trim="form.title"
            clearable
            placeholder="请输入"
          ></el-input></el-form-item
      ></el-col>
      <el-col :xs="24" :sm="12" :md="3">
        <el-form-item label="次序" prop="seq" class="base-form-item">
          <el-input
            type="number"
            v-model.trim="form.seq"
            clearable
            placeholder="请输入"
          ></el-input> </el-form-item
      ></el-col>
    </el-row>

    <el-row :gutter="10">
      <el-col :xs="24" :sm="12" :md="8"
        ><el-form-item label="摘要" prop="subTitle" class="max-width-600">
          <el-input
            type="textarea"
            :rows="4"
            v-model.trim="form.subTitle"
            clearable
            placeholder="请输入"
          ></el-input> </el-form-item
      ></el-col>
      <el-col :xs="24" :sm="12" :md="8">
        <el-form-item label="封面图" prop="coverImageUrl">
          <SingleImgUpload
            v-model="form.coverImageUrl"
            class="cover-image"
          /> </el-form-item
      ></el-col>
    </el-row>
    <el-row>
      <el-col :xs="24" :sm="24" :md="20" :lg="18">
        <el-form-item label="内容" prop="content">
          <Tinymce
            v-model="form.content"
            :height="1200"
            v-if="showRichEditor"
          />
          
          <div class="editor-loading" v-if="!showRichEditor">
            <i class="el-icon-loading"></i>
            编辑器初始化中...
          </div>
        </el-form-item>
      </el-col>
    </el-row>

    <el-form-item style="text-align: center">
      <!-- <el-button @click="onCancel" :disabled="submitting" :loading="loading"
        >取 消</el-button
      > -->
      <el-button
        v-if="isEmprty(this.currentId)"
        type="primary"
        icon="el-icon-s-promotion"
        :loading="submitting"
        @click="submitForm('blogForm')"
        >{{ submitting ? "保存中..." : "保存" }}</el-button
      >
      <el-button
        type="warning"
        icon="el-icon-s-promotion"
        :loading="submitting"
        @click="editForm('blogForm')"
        v-if="!isEmprty(this.currentId)"
        >{{ submitting ? "保存中..." : "保存修改" }}</el-button
      >
    </el-form-item>
  </el-form>
</template>

<script>
import { GetBlogById, PostBlogs, PutBlogById } from "../api";
import { ShowApiError } from "@/request/error";
import { isEmprty } from "@/utils/validate";
import Tinymce from "@/components/Tinymce";
import SingleImgUpload from "@/components/SingleImgUpload";
export default {
  components: {
    Tinymce,
    SingleImgUpload,
  },
  props: {
    uid: {
      type: [Number, String],
      default: "",
    },
  },
  data() {
    return {
      loading: false,
      submitting: false,
      showRichEditor: true,
      currentId: null,
      form: {
        title: "",
        subTitle: "",
        coverImageUrl: "",
        content: "",
        seq: 0,
      },
      rules: {
        title: [{ required: true, message: "请输入用户名称", trigger: "blur" }],

        content: [{ required: true, message: "请输入内容", trigger: "blur" }],
      },
    };
  },
  watch: {
    uid: {
      handler(value) {
        if (!isEmprty(this.uid)) {
          this.currentId = value;
          this.getFormData();
        } else {
          this.currentId = null;
          this.inintForm();
        }
      },
      immediate: true,
    },
  },

  methods: {
    isEmprty,
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submitting = true;
          PostBlogs([this.form])
            .then((res) => {
              this.submitting = false;
              this.onEmitSuccess(res.data);
            })
            .catch((err) => {
              this.submitting = false;
              ShowApiError("新增失败", err);
            });
        } else {
          this.$message.warning("表单有误，请检查");
          return false;
        }
      });
    },
    editForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submitting = true;
          PutBlogById(this.currentId, this.form)
            .then((res) => {
              this.submitting = false;
              this.inintForm();
              this.$message.success("修改成功");
              this.getFormData();
              this.onEmitSuccess(res.data);
            })
            .catch((err) => {
              this.submitting = false;
              ShowApiError("修改失败", err);
            });
        } else {
          this.$message.warning("表单有误，请检查");
          return false;
        }
      });
    },
    getFormData() {
      this.loading = true;
      this.inintForm();
      GetBlogById(this.currentId)
        .then((res) => {
          for (const key in res.data) {
            if (Object.hasOwnProperty.call(this.form, key)) {
              this.form[key] = res.data[key];
            }
          }
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          ShowApiError("获取表单", err);
        });
    },

    onCancel(data = {}) {
      this.$emit("cancel", data);
    },
    onEmitSuccess(data = {}) {
      this.$emit("success", data);
    },
    inintForm() {
      this.$nextTick(() => {
        this.$refs["blogForm"].resetFields();
        this.resetRichComponent();
      });
    },
    resetRichComponent() {
      this.showRichEditor = false;
      let timer = null;
      timer = setTimeout(() => {
        clearTimeout(timer);
        this.showRichEditor = true;
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
.cover-image {
  width: 150px;
  height: 90px;
  ::v-deep {
    .img-upload-wrapper {
      height: auto;
    }
    .uploaded-img-preview {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
.company-ruleForm {
  box-sizing: border-box;
  padding: 10px;
  .max-width-600 {
    max-width: 600px;
  }
  .base-form-item {
    max-width: 600px;
    overflow: hidden;
    ::v-deep {
      .el-form-item__content {
        height: 40px;
        line-height: 40px;
      }
    }
  }
  .editor-loading {
    width: 100%;
    padding: 10px 0;
    text-align: center;
    font-size: 14px;
    color: #989898;
  }
}
</style>
