<template>
  <div class="page-wrapper">
    <PageHeader class="base-page-header base-shadow"> </PageHeader>
    <Form :uid="currentId" />
  </div>
</template>

<script>
import { isEmprty } from "@/utils/validate";
import PageHeader from "@/components/PageHeader";
import Form from "./components/Form.vue";
export default {
  name: "ProductFormPage",
  components: { Form,PageHeader },
  data() {
    return {
      currentId: null,
      editableTabsValue: "",
      currentForms: [],
    };
  },
  watch: {
    $route: {
      handler() {
        if (this.$route.name === "BlogForm") {
          if (this.$route.params.id && !isEmprty(this.$route.params.id)) {
            this.currentId = this.$route.params.id;
          } else {
            this.currentId = null;
          }
        }
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.page-wrapper {
  box-sizing: border-box;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  background: #fff;
}
</style>
